<template>
	<div id="operation_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="userNa" label="登录账号:">
				<el-input placeholder="请输入" v-model="form.userNa"></el-input>
			</jy-query-item>
			<jy-query-item prop="markNa" label="用户名称:">
				<el-input placeholder="请输入" v-model="form.markNa"></el-input>
			</jy-query-item>
			<jy-query-item prop="module" label="操作模块:">
				<el-input placeholder="请输入" v-model="form.module"></el-input>
			</jy-query-item>
			<jy-query-item prop="type" label="操作类型:">
				<el-select v-model="form.type" placeholder="请选择">
					<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsOperationLogList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsOperationLogReset')"
					>重置</el-button
				>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="operator" label="登录账号" min-width="100"></jy-table-column>
			<jy-table-column prop="name" label="用户名称" min-width="180"></jy-table-column>
			<jy-table-column prop="orgNa" label="所属机构" min-width="180"></jy-table-column>
			<jy-table-column prop="roleNa" label="所属角色" min-width="180"></jy-table-column>
			<jy-table-column prop="module" label="操作模块" min-width="120"></jy-table-column>
			<jy-table-column prop="page" label="操作页面" min-width="200"></jy-table-column>
			<jy-table-column prop="typeName" label="操作类型" min-width="100"></jy-table-column>
			<jy-table-column prop="ip" label="操作IP" min-width="120"></jy-table-column>
			<jy-table-column prop="time" label="操作时间" min-width="160"></jy-table-column>
			<jy-table-column prop="describe" label="操作描述" min-width="200"></jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
	</div>
</template>
<script>
const statusList = [
	{
		label: "全部",
		value: ""
	},
	{
		label: "新增",
		value: "1"
	},
	{
		label: "修改",
		value: "2"
	},
	{
		label: "查看",
		value: "3"
	},
	{
		label: "删除",
		value: "4"
	},
	{
		label: "导入",
		value: "5"
	},
	{
		label: "导出",
		value: "6"
	},
	{
		label: "登录",
		value: "7"
	},
	{
		label: "退出登录",
		value: "8"
	},
	{
		label: "重置密码",
		value: "9"
	},
	{
		label: "批量删除",
		value: "10"
	},
	{
		label: "发布",
		value: "11"
	},
	{
		label: "菜单权限",
		value: "12"
	}
];
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			statusList,
			form: {
				userNa: "",
				markNa: "",
				module: "",
				type: ""
			},
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],

			btnMenuId: "scheduleSystemSettingsOperationLog"
		};
	},
	mixins: [btnMixins],
	created() {
		this.getList();
	},
	methods: {
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/system/userLog/queryUserOperateLog";
			console.log(option);
			this.$http.post(url, option).then(({ detail }) => {
				detail.list.forEach(item => {
					switch (item.type) {
						case "1":
							item.typeName = "新增";
							break;
						case "2":
							item.typeName = "修改";
							break;
						case "3":
							item.typeName = "查看";
							break;
						case "4":
							item.typeName = "删除";
							break;
						case "5":
							item.typeName = "导入";
							break;
						case "6":
							item.typeName = "导出";
							break;
						case "7":
							item.typeName = "登录";
							break;
						case "8":
							item.typeName = "退出登录";
							break;
						case "9":
							item.typeName = "重置密码";
							break;
						case "10":
							item.typeName = "批量删除";
							break;
						case "11":
							item.typeName = "发布";
							break;
						case "12":
							item.typeName = "菜单权限";
							break;
					}
				});
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
